import './MessageComponent.scss';
import { Message } from "../ToxicityMenu";
import DOMPurify from 'dompurify';

type MessageComponentProps = {
    children: any;
    message: Message;
}

const MessageComponent = ({children, message}: MessageComponentProps) => {
    return <div className={`message ${message.role} ${message.isSystem && "system"}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children)}}>
    </div>
}

export default MessageComponent;