import { useEffect, useRef, useState } from "react";
import { Abilities, Message } from "./ToxicityMenu";
import {ReactComponent as RightArrow} from "./RightArrow.svg";
import {ReactComponent as D20Icon} from "./D20Icon.svg";
import {ReactComponent as ProfileIcon} from "./ProfileIcon.svg";
import QuillGif from "./assets/quill-and-scribble.gif";
import CharacterSheetComponent, { CharacterSheet } from "./CharacterSheetComponent";
import { Game } from "./Games/games";
import MessageComponent from "./Message/MessageComponent";

type ToxicityConversationProps = {
    messages: Message[];
    abilityCheck: Abilities;
    isLoading: boolean; 
    characterSheet: CharacterSheet;
    game: Game;
    rollDie: () => void;
    submitPrompt: (val: string, sys?: boolean) => void;
    submitCheck: (val: number) => void;
};

const ToxicityConversation = ({ messages, abilityCheck, isLoading, characterSheet, game, rollDie, submitPrompt, submitCheck }: ToxicityConversationProps) => {
    const textarea                    = useRef(null);
    const checkinput                  = useRef(null);
    const [checkValue, setCheckValue] = useState<number>(10);
    const [sheetOpen, setSheetOpen]   = useState<boolean>(false);

    useEffect(() => {
        startGame(game);

        if (textarea.current) {
            textarea.current.focus();
        }
        const keydown = (e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (document.activeElement === textarea?.current) {
                    handleSend();
                } else if (document.activeElement === checkinput?.current) {
                    handleCheck();
                }
            }
        };

        document.addEventListener("keydown", keydown);

        return () => {
            document.removeEventListener("keydown", keydown);
        };
    }, []);

    useEffect(() => {
        if (!isLoading && !abilityCheck) {
            textarea.current.focus();
        }
    }, [isLoading]);

    const handleSend = () => {
        submitPrompt(textarea?.current?.value);
        textarea.current.value = "";
    };

    const handleCheck = () => {
        submitCheck(checkinput.current?.value);
        checkinput.current.value = 10;
    };

    const startGame = (game) => {
        submitPrompt(game.init, true);
    };

    return <div className="toxicity-conversation-wrapper">
        <div className="conversation-column">
            <div className="conversation-messages">
                {abilityCheck && <div className="ability-check">
                    <button onClick={rollDie}>
                        <D20Icon width={40} height={40}/>
                    </button>
                    <input
                        type="text"
                        onChange={(v) => {
                            let value = parseInt(v.target.value);
                            if (isNaN(value)) {
                                return;
                            }
                            if (value > 20) {
                                value = 20;
                            } else if (value < 1) {
                                value = 1;
                            }
                            setCheckValue(value)
                        }}
                        value={checkValue}
                        ref={checkinput}/>
                    <button disabled={isLoading} onClick={handleCheck}>
                        <RightArrow width={40} height={40}/>
                    </button>
                </div>}
                {messages.map((msg, i) => <MessageComponent key={i} message={msg}>{msg.msg}</MessageComponent>)}
            </div>
            <div className="conversation-footer-container">
                {isLoading  && <div className="loading-icon-container">
                    <img src={QuillGif}></img>
                </div>}
                <div className="conversation-footer">
                    <textarea rows={4} disabled={isLoading || !!abilityCheck} placeholder="Type out your query for the campaign" ref={textarea}></textarea>
                    <button disabled={isLoading || !!abilityCheck} onClick={handleSend}>
                        <RightArrow width={40} height={40}/>
                    </button>
                </div>
            </div>
        </div>
        <div className="character-sheet-column">
            <CharacterSheetComponent
                game={game}
                characterSheet={characterSheet}
                onClose={() => setSheetOpen(false)}/>
        </div>
    </div>
};

export default ToxicityConversation;