import dungeonsanddragons from "./dungeonsanddragons.jpg";
import dungeonsandzombies from "./dungeonsandzombies.jpg"
import cyberpunkred from "./cyberpunkred.jpg";
import trenchcrusade from "./trenchcrusade.jpg";

export type Game = {
    title: string,
    description: string,
    background: any,
    init: string,
    classes: Class[],
    randomEncounters: string[],
    races?: string[]
};

export type Class = {
    title: string,
    subclasses?: {
        title: string
    }[]
}

export const games: Game[] = [
    {
        title: "Fantasy Template",
        description: "A generic fantasy world where you get to choose your own adventure",
        background: null,
        init: "The player chose the setting of a basic fantasy world, provide them with a setting containing all the fantasy cliches, adventures, races, kingdoms, magic, and monsters.",
        classes: [
            {
                title: "Warrior"
            },
            {
                title: "Mage"
            },
            {
                title: "Ranger"
            },
            {
                title: "Rogue"
            }
        ],
        races: [
            "Dwarf",
            "Elf",
            "Human",
            "Orc"
        ],
        randomEncounters: []
    },
    {
        title: "Dungeons and Dragons",
        description: "The classic roleplaying experience,",
        background: dungeonsanddragons,
        init: "The player chose to play in the default Dungeons and Dragons setting, they deserve a versatile and free world filled with all the cliche fantasy excitement that the game has to offer.",
        classes: [
            {
                title: "Artificer",
                subclasses: [
                    {
                        title: "Alchemist",
                    },
                    {
                        title: "Armorer",
                    },
                    {
                        title: "Artillerist",
                    },
                    {
                        title: "Battle Smith"
                    }
                ]
            },
            {
                title: "Barbarian",
                subclasses: [
                    {
                        title: "Path of the Ancestral Guardian"
                    },
                    {
                        title: "Path of the Battlerager"
                    },
                    {
                        title: "Path of the Beast"
                    },
                    {
                        title: "Path of the Berserker"
                    },
                    {
                        title: "Path of the Storm Herald"
                    },
                    {
                        title: "Path of the Totem Warrior"
                    },
                    {
                        title: "Path of the Zealot"
                    },
                    {
                        title: "Path of Wild Magic"
                    }
                ]
            },
            {
                title: "Bard",
                subclasses: [
                    {
                        title: "College of Creation"
                    },
                    {
                        title: "College of Eloquence"
                    },
                    {
                        title: "College of Glamour"
                    },
                    {
                        title: "College of Lore"
                    },
                    {
                        title: "College of Spirits"
                    },
                    {
                        title: "College of Swords"
                    },
                    {
                        title: "College of Valor"
                    },
                    {
                        title: "College of Whispers"
                    }
                ]
            },
            {
                title: "Cleric",
                subclasses: [
                    {
                        title: "Arcana Domain"
                    },
                    {
                        title: "Death Domain"
                    },
                    {
                        title: "Forge Domain"
                    },
                    {
                        title: "Grave Domain"
                    },
                    {
                        title: "Knowledge Domain"
                    },
                    {
                        title: "Life Domain"
                    },
                    {
                        title: "Light Domain"
                    },
                    {
                        title: "Nature Domain"
                    },
                    {
                        title: "Order Domain"
                    },
                    {
                        title: "Peace Domain"
                    },
                    {
                        title: "Tempest Domain"
                    },
                    {
                        title: "Trickery Domain"
                    },
                    {
                        title: "Twilight Domain"
                    },
                    {
                        title: "War Domain"
                    }
                ]
            },
            {
                title: "Druid",
                subclasses: [
                    {
                        title: "Circle of Dreams"
                    },
                    {
                        title: "Circle of Spores"
                    },
                    {
                        title: "Circle of Stars"
                    },
                    {
                        title: "Circle of the Land"
                    },
                    {
                        title: "Circle of the Moon"
                    },
                    {
                        title: "Circle of the Shepherd"
                    },
                    {
                        title: "Circle of Wildfire"
                    }
                ]
            },
            {
                title: "Fighter",
                subclasses: [
                    {
                        title: "Arcane Archer"
                    },
                    {
                        title: "Battle Master"
                    },
                    {
                        title: "Cavalier"
                    },
                    {
                        title: "Champion"
                    },
                    {
                        title: "Echo Knight"
                    },
                    {
                        title: "Eldritch Knight"
                    },
                    {
                        title: "Psi Warrior"
                    },
                    {
                        title: "Purple Dragon Knight"
                    },
                    {
                        title: "Rune Knight"
                    },
                    {
                        title: "Samurai"
                    },
                ]
            },
            {
                title: "Monk",
                subclasses: [
                    {
                        title: "Way of Mercy"
                    },
                    {
                        title: "Way of Shadow"
                    },
                    {
                        title: "Way of the Astral Self"
                    },
                    {
                        title: "Way of the Drunken Master"
                    },
                    {
                        title: "Way of the Four Elements"
                    },
                    {
                        title: "Way of the Kensei"
                    },
                    {
                        title: "Way of the Long Death"
                    },
                    {
                        title: "Way of the Open Hand"
                    },
                    {
                        title: "Way of the Sun Soul"
                    }
                ]
            },
            {
                title: "Paladin",
                subclasses: [
                    {
                        title: "Oath of Conquest"
                    },
                    {
                        title: "Oath of Devotion"
                    },
                    {
                        title: "Oath of Glory"
                    },
                    {
                        title: "Oath of Redemption"
                    },
                    {
                        title: "Oath of the Ancients"
                    },
                    {
                        title: "Oath of the Crown"
                    },
                    {
                        title: "Oath of the Watchers"
                    },
                    {
                        title: "Oath of Vengeance"
                    },
                    {
                        title: "Oathbreaker"
                    }
                ]
            },
            {
                title: "Ranger",
                subclasses: [
                    {
                        title: "Beast Master"
                    },
                    {
                        title: "Fey Wanderer"
                    },
                    {
                        title: "Gloom Stalker"
                    },
                    {
                        title: "Horizon Walker"
                    },
                    {
                        title: "Hunter"
                    },
                    {
                        title: "Monster Slayer"
                    },
                    {
                        title: "Swarm Keeper"
                    }
                ]
            },
            {
                title: "Rogue",
                subclasses: [
                    {
                        title: "Arcane Trickster"
                    },
                    {
                        title: "Assassin"
                    },
                    {
                        title: "Inquisitive"
                    },
                    {
                        title: "Mastermind"
                    },
                    {
                        title: "Phantom"
                    },
                    {
                        title: "Scout"
                    },
                    {
                        title: "Soulknife"
                    },
                    {
                        title: "Swashbuckle"
                    },
                    {
                        title: "Thief"
                    }
                ]
            },
            {
                title: "Sorcerer",
                subclasses: [
                    {
                        title: "Aberrant Mind"
                    },
                    {
                        title: "Clockwork Soul"
                    },
                    {
                        title: "Divine Soul"
                    },
                    {
                        title: "Draconic Bloodline"
                    },
                    {
                        title: "Shadow Magic"
                    },
                    {
                        title: "Storm Sorcery"
                    },
                    {
                        title: "Wild Magic"
                    }
                ]
            },
            {
                title: "Warlock",
                subclasses: [
                    {
                        title: "The Archfey"
                    },
                    {
                        title: "The Celestial"
                    },
                    {
                        title: "The Fathomless"
                    },
                    {
                        title: "The Fiend"
                    },
                    {
                        title: "The Genie"
                    },
                    {
                        title: "The Great Old One"
                    },
                    {
                        title: "The Hexblade"
                    },
                    {
                        title: "The Undead"
                    },
                    {
                        title: "The Undying"
                    }
                ]
            },
            {
                title: "Wizard",
                subclasses: [
                    {
                        title: "Bladesinging"
                    },
                    {
                        title: "Chronurgy Magic"
                    },
                    {
                        title: "Graviturgy Magic"
                    },
                    {
                        title: "Order of Scribes"
                    },
                    {
                        title: "School of Abjuration"
                    },
                    {
                        title: "School of Conjuration"
                    },
                    {
                        title: "School of Divination"
                    },
                    {
                        title: "School of Enchantment"
                    },
                    {
                        title: "School of Evocation"
                    },
                    {
                        title: "School of Illusion"
                    },
                    {
                        title: "School of Necromancy"
                    },
                    {
                        title: "School of Transmutation"
                    },
                    {
                        title: "War Magic"
                    }
                ]
            }
        ],
        races: [
            "Dwarf",
            "Elf",
            "Human",
            "Halfling"
        ],
        randomEncounters: []
    },
    {
        title: "D&Z",
        description: "Survive in the modern day zombie apocalypse",
        background: dungeonsandzombies,
        init: "The game setting will be in D&Z, which is a modern-day zombie apocalypse game that uses standard 5e rules",
        classes: [
            {
                title: "Burgular",
                subclasses: [
                    {
                        title: "Mugger"
                    },
                    {
                        title: "Bank Robber"
                    }
                ]
            },
            {
                title: "Mechanic",
                subclasses: [
                    {
                        title: "Car Mechanic"
                    },
                    {
                        title: "Small Engine Mechanic"
                    }
                ]
            },
            {
                title: "Chef",
                subclasses: [
                    {
                        title: "Kitchen Cook"
                    },
                    {
                        title: "Baker"
                    }
                ]
            },
            {
                title: "Computer Geek",
                subclasses: [
                    {
                        title: "IT Guy"
                    },
                    {
                        title: "Hacker"
                    }
                ]
            },
            {
                title: "Construction Worker",
                subclasses: [
                    {
                        title: "Carpenter"
                    },
                    {
                        title: "Electrician"
                    }
                ]
            },
            {
                title: "Customer Service",
                subclasses: [
                    {
                        title: "Customer Relations"
                    },
                    {
                        title: "Sales"
                    }
                ]
            },
            {
                title: "Doctor",
                subclasses: [
                    {
                        title: "Wilderness Medicine"
                    },
                    {
                        title: "Psychologist"
                    }
                ]
            },
            {
                title: "Farmer",
                subclasses: [
                    {
                        title: "Gardener"
                    },
                    {
                        title: "Rancher"
                    }
                ]
            },
            {
                title: "Firefigher/EMT",
                subclasses: [
                    {
                        title: "Waterlogged"
                    },
                    {
                        title: "Pyro"
                    }
                ]
            },
            {
                title: "Military",
                subclasses: [
                    {
                        title: "Special Operations"
                    },
                    {
                        title: "Explosive Specialist"
                    }
                ]
            },
            {
                title: "Musician or Theater Kid",
                subclasses: [
                    {
                        title: "Singer"
                    },
                    {
                        title: "Songwriter"
                    }
                ]
            },
            {
                title: "Office Worker",
                subclasses: [
                    {
                        title: "Secretary"
                    },
                    {
                        title: "Human Resources"
                    }
                ]
            },
            {
                title: "Police Officer",
                subclasses: [
                    {
                        title: "School Liaison Officer"
                    },
                    {
                        title: "Patrol Officer"
                    }
                ]
            },
            {
                title: "Scientist",
                subclasses: [
                    {
                        title: "Biologist"
                    },
                    {
                        title: "Astronomer"
                    }
                ]
            },
            {
                title: "Student",
                subclasses: [
                    {
                        title: "Intern"
                    },
                    {
                        title: "Partier"
                    }
                ]
            },
            {
                title: "Teacher",
                subclasses: [
                    {
                        title: "Substitute Teacher"
                    },
                    {
                        title: "Math Teacher"
                    }
                ]
            }
        ],
        randomEncounters: []
    }, {
        title: "Trench Crusade",
        description: "A bleak alternate history where the forces of heaven and hell clash in the WW1 trenches of 1914",
        background: trenchcrusade,
        init: "The setting of this campaign is the grimdark world of Trench Crusade. During the first crusade in the year 1099, a rebellious group of templars encountered a demonic artifact in the holy land of Jerusalem and opened a gate to Hell itself. It is now the year 1914 of our Lord, trenches and artillery shells scathe the landscape of No Man's Land.",
        classes: [
            {
                title: "War Prophet"
            },
            {
                title: "Trench Pilgrim"
            },
            {
                title: "Heretic Priest"
            }
        ],
        randomEncounters: []
    }, {
        title: "Cyberpunk Red",
        description: "Live in the year 2044, where corporations and gangs use cyber augmentations to take control of the streets of Night City",
        background: cyberpunkred,
        init: "This RPG will take the scene of Cyberpunk Red, set in the year 2044 where corporate warfare and criminal activity is bolstered by cyberaugmentation technology and netrunners",
        classes: [
            {
                title: "Netrunner"
            },
            {
                title: "Nomad"
            },
            {
                title: "Corpo Agent"
            },
            {
                title: "Streetkid"
            }
        ],
        randomEncounters: []
    }
];