import { Class, Game } from "./Games/games";
import { Die } from "./ToxicityMenu";
import { useState } from "react";


export type Weapon = {
    type: string;
    range: number;
    damage: number
};

export type Armor = {
    defense: number;
    material: "leather" | "iron";
    piece: "helmet" | "chestplate" | "leggings" | "boots";
};

export type Potion = {
    effect: "health" | "charisma";
    duration: "number";
    potency: "number;"
};

export type CharacterSheet = {
    name: string;
    health: number;
    max_health: number;
    level: number;
    class: Class;
    subclass?: string;
    race: string;
    stats: {
        str: number;
        int: number;
        wis: number;
        dex: number;
        con: number;
        cha: number;
    };
    inventory: {
        type: "weapon" | "armor_piece" | "potion";
        stats: Weapon | Potion | Armor
    }[];
};

type CharacterSheetProps = {
    characterSheet: CharacterSheet;
    game: Game;
    onClose: () => void;
    editing?: boolean;
    isModal?: boolean;
};

const CharacterSheetComponent = ({characterSheet, game, onClose, editing = false, isModal = false}: CharacterSheetProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(editing);
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const handleClose = () => {
        if (isModal) {
            setIsClosing(true);
            setTimeout(() => {
                onClose();
            }, 200);
        } else {
            onClose();
        }
    }

    console.log(characterSheet);

    return <div className={`character-sheet-container ${isClosing && "closing"} ${isModal && "modal"}`}>
        <div className="sheet-backdrop" onClick={() => handleClose()}></div>
        <div className="character-sheet" onClick={(e) => {e.preventDefault()}}>
            <div className="character-header">
                <div className="character-person">
                    <div className="character-picture"></div>
                    <div className="character-status">
                        <div className="character-level">Lvl {characterSheet.level}</div>
                        <div className="character-health">HP: {characterSheet.health} / {characterSheet.max_health}</div>
                    </div>
                    <div className="character-name">{characterSheet.name}</div>
                </div>
            </div>
            <div className="character-classes">
                <Property property="class">
                    <div className="label">Class:</div>
                    {isEditing ? <select value={characterSheet.class?.title} onChange={console.log}>
                        {game.classes.map((c, i) => <option key={i}>{c.title}</option>)}
                    </select> : characterSheet.class?.title}
                </Property>
                {characterSheet.class && game.classes.filter(c => c === characterSheet.class)[0]?.subclasses?.length > 0 && <Property property="subclass">
                    <div className="label">Subclass:</div>
                    <select onChange={console.log}></select>
                </Property>}
                <Property property="race">
                    <div className="label">Race:</div>
                    {isEditing ? <select value={characterSheet.race} onChange={console.log}>
                        {game.races.map((r, i) => <option key={i}>{r}</option>)}
                    </select> : characterSheet.race}
                </Property>
                {/* <Property property="subrace">
                    <div className="label">Subrace:</div>
                    <select></select>
                </Property> */}
                {/* <Property property="level">
                    <div className="label">Level:</div>
                </Property> */}
            </div>
            <div className="character-stats">
                {Object.entries(characterSheet.stats).map(([key, value]) => 
                    <div className="stat-block" key={key}>
                        <div className="stat-label">{key.toUpperCase()}</div>
                        <div className="stat-value">{value}</div>
                    </div>
                )}
            </div>
            <div className="character-inventory">
                {characterSheet.inventory.map((i, index) => <div key={index}>
                    <h4>{i.type}</h4>
                    {Object.entries(i.stats).map(([key, value]) => <div>
                        {key} : {value}
                    </div>)}
                </div>)}
            </div>
            {isEditing && <div className="character-footer">
                <button onClick={handleClose}>Start Game</button>
            </div>}
        </div>
    </div>
};

const Property = ({children, property}) => {
    return <div className="property">
        {children}
    </div>
};

export default CharacterSheetComponent;